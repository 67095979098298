import { DocumentIconNames } from '@nesto/design-system/v2';

export const documentContentType: DocumentContentTypeValues = {
    'application/msword': 'DOC',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        'DOCX',
    'image/gif': 'GIF',
    'image/heif': 'HEIF',
    'image/heic': 'HEIC',
    // 'image/jpeg': 'JPEG',
    'image/jpeg': 'JPG',
    'application/vnd.oasis.opendocument.presentation': 'ODP',
    'application/vnd.oasis.opendocument.spreadsheet': 'ODS',
    'application/vnd.oasis.opendocument.text': 'ODT',
    'application/pdf': 'PDF',
    'image/png': 'PNG',
    'application/vnd.ms-powerpoint': 'PPT',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        'Pptx',
    'text/plain': 'TXT',
    'application/vnd.ms-excel': 'XLS',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX'
};

// should rename this?? DocumentContentType exists already though
type DocumentContentTypeValues = {
    [key: string]: DocumentIconNames;
};
