import nanoid from 'nanoid';
import * as R from 'ramda';
import { all, call, put, select } from 'redux-saga/effects';

import {
    BoostEmailInfo,
    Document,
    DocumentCompositeKey,
    DocumentContentType,
    documentContentType,
    DocumentFile,
    DocumentFileCreateData,
    DocumentStep
} from 'models/documents';
import {
    DocumentsTypes,
    Actions as DocumentsActions,
    ReduxAction
} from 'reducers/documents.redux';
import {
    getApplicationId,
    getCurrentApplicationApplicantId
} from 'reducers/application.selectors';
import {
    errorNotification,
    successNotification
} from 'reducers/notifications.redux';
import { apiClient } from 'services/api';
import { log } from 'utils/logging';
import {
    getRouteDocumentStep,
    getHasFileTooBigError
} from 'reducers/documents.selectors';

export function* getDocumentTypes() {
    const applicationId: number = yield select(getApplicationId);
    const applicantId: number = yield select(getCurrentApplicationApplicantId);
    try {
        const { data, ok } = yield call(
            apiClient.getDocumentTypes,
            applicationId,
            applicantId
        );

        if (!ok) {
            throw new Error(data.error);
        }

        yield put({
            type: DocumentsTypes.GET_DOCUMENT_TYPES_SUCCESS,
            types: data
        });
    } catch (error) {
        yield put({
            type: DocumentsTypes.GET_DOCUMENT_TYPES_ERROR,
            error
        });
    }
}
// get all applicant documents
export function* allDocumentsRequest({ applicantIds }) {
    const applicationId: number = yield select(getApplicationId);

    try {
        let allData = [] as any;
        for (let index = 0; index < applicantIds.length; index++) {
            const selectedApplicantId = applicantIds[index];
            const { data, ok } = yield call(
                apiClient.getDocuments,
                applicationId,
                selectedApplicantId,
                0
            );
            if (!ok) {
                throw new Error(data.error);
            }
            allData = [...allData, ...data];
        }

        yield put({
            type: DocumentsTypes.DOCUMENTS_SUCCESS,
            documents: allData
                ? allData.map(document => ({
                      files: [], // Add file here because API omit on null | undefined
                      messages: [], // newly created doc doesnt's have this glued
                      ...document
                  }))
                : []
        });
    } catch (error) {
        yield put({
            type: DocumentsTypes.DOCUMENTS_ERROR,
            error
        });
    }
}
// get applicant documents
export function* documentsRequest({
    step
}: ReduxAction<{ step: DocumentStep }>) {
    const applicationId: number = yield select(getApplicationId);
    const applicantId: number = yield select(getCurrentApplicationApplicantId);

    try {
        const { data, ok } = yield call(
            apiClient.getDocuments,
            applicationId,
            applicantId,
            step
        );

        if (!ok) {
            throw new Error(data.error);
        }

        yield put({
            type: DocumentsTypes.DOCUMENTS_SUCCESS,
            documents: data
                ? data.map(document => ({
                      files: [], // Add file here because API omit on null | undefined
                      messages: [], // newly created doc doesnt's have this glued
                      ...document
                  }))
                : []
        });
    } catch (error) {
        yield put({
            type: DocumentsTypes.DOCUMENTS_ERROR,
            error
        });
    }
}

// send boost email for documents
export function* sendDocumentBoostEmail() {
    const applicationId: number = yield select(getApplicationId);
    const applicantId: number = yield select(getCurrentApplicationApplicantId);
    try {
        const { data, ok } = yield call(
            apiClient.sendDocumentBoostEmail,
            applicationId,
            applicantId
        );
        if (!ok) {
            errorNotification({
                text: 'documents.boostEmail.noReminderDocuments'
            });
        }

        yield put({
            type: DocumentsTypes.DOCUMENTS_BOOST_SUCCESS,
            lastBoostSentInfo: data
        });
    } catch (error) {
        yield put({
            type: DocumentsTypes.DOCUMENTS_ERROR,
            error
        });
    }
}
// get latest boost email
export function* getLastDocumentBoostEmail() {
    const applicationId: number = yield select(getApplicationId);
    const applicantId: number = yield select(getCurrentApplicationApplicantId);
    try {
        const { data, ok } = yield call(
            apiClient.getLastDocumentBoostEmail,
            applicationId,
            applicantId
        );
        if (!ok) {
            yield put({
                type: DocumentsTypes.DOCUMENTS_BOOST_ERROR
            });
        }
        if (ok) {
            // should sort by latest
            const [latestBoostEmail]: BoostEmailInfo[] = data.sort(function(
                a,
                b
            ) {
                return a.Sent - b.Sent;
            });

            yield put({
                type: DocumentsTypes.DOCUMENTS_BOOST_SUCCESS,
                lastBoostSentInfo: latestBoostEmail
            });
        }
    } catch (error) {
        yield put({
            type: DocumentsTypes.DOCUMENTS_ERROR,
            error
        });
    }
}

export function* documentsError(error: any) {
    try {
        log(error);
        yield put(
            errorNotification({
                text: 'documents.documentsError'
            })
        );
    } catch (err) {
        log(error);
    }
}

// Core of upload (multi-file) request
export function* fileUploadRequest({
    files,
    document,
    isNewDocument = false
}: {
    files: File[];
    document: Document;
    isNewDocument: boolean;
}) {
    try {
        // Create document on Backend  if new
        if (isNewDocument) {
            yield put({
                type: DocumentsTypes.CREATE_DOCUMENT_REQUEST
            });

            // Send request to back to create document
            const { ok, data } = yield call(
                apiClient.createDocument,
                R.omit(['isNew'], document)
            );

            if (!ok) {
                throw new Error('Failed to create new document');
            }

            yield put({
                type: DocumentsTypes.CREATE_DOCUMENT_SUCCESS,
                document: {
                    ...document,
                    ...data
                }
            });
        }

        // Add and upload files into document
        const allResponse = yield all(
            files.map(file => {
                const newFile: DocumentFile = {
                    uploadKey: nanoid(),
                    uploaded: false,
                    uploading: true,
                    originalFileName: file.name,
                    contentType: file.type as DocumentContentType,
                    file
                };

                return call(fileUpload, newFile, document);
            })
        );

        // Check if one upload reponse contain ok: false
        const oks = R.pluck<'ok', { ok: boolean; data: any }>(
            'ok',
            allResponse
        );
        const hasFailedToUpload = oks.includes(false) || !oks.length;
        if (hasFailedToUpload) {
            throw new Error('Error while uploading one or multiple files');
        }

        yield put({
            type: DocumentsTypes.FILE_UPLOAD_SUCCESS
        });

        yield put(DocumentsActions.documentsCountsRequest());
    } catch (error) {
        yield put({
            type: DocumentsTypes.FILE_UPLOAD_ERROR,
            error
        });
    }
}

export function* fileUploadSuccess() {
    const hasFileTooBigError: boolean = yield select(getHasFileTooBigError);

    if (!hasFileTooBigError)
        yield put(
            successNotification({
                text: 'application.dataUpdatedSuccess'
            })
        );

    yield put(DocumentsActions.updateHasFileTooBigError(false));
}

export function* fileUploadError(error: any) {
    try {
        log(error);
        yield put(
            errorNotification({
                text: 'application.dataUpdatedFailure'
            })
        );
    } catch (err) {
        log(error);
    }

    yield put(DocumentsActions.updateHasFileTooBigError(false));
}

// File upload: acquire signed URL from backend then upload to GCS then add file to document
function* fileUpload(file: DocumentFile, document: Document) {
    try {
        if (!file.file) {
            return;
        }

        // validate file contentType here
        if (!Object.keys(documentContentType).includes(file.file.type)) {
            throw new Error(
                `Upload failed: Unsupported file type of "${file.file.type}"`
            );
        }

        // Validate max file size +- 20MIB in bytes
        if (file.file.size > 2.097e7) {
            yield put(
                errorNotification({
                    text: 'documents.error.fileTooLarge'
                })
            );
            yield put(DocumentsActions.updateHasFileTooBigError(true));

            return false;
        }

        // Merge file into REDUX STATE to show file with spinner
        yield put({
            type: DocumentsTypes.UPLOAD_FILE_REQUEST,
            file,
            document
        });

        const pickNewFileProp = R.pick(['contentType', 'originalFileName']) as (
            file: DocumentFile
        ) => DocumentFileCreateData;
        const newFileProps = pickNewFileProp(file);

        // Acquire an signed upload URL
        // - call API signed URL
        const { ok, data } = yield call(
            apiClient.acquireSignedUrl,
            document,
            newFileProps
        );

        if (!ok) {
            throw new Error(data.error);
        }

        // - Upload to GCS
        yield call(apiClient.uploadDocument, data.url, file.file);

        // - Confirm upload to backend
        const { ok: confirmedOk, data: confirmedData } = yield call(
            apiClient.addDocumentFile,
            document,
            {
                ...newFileProps,
                fileId: data.fileId
            }
        );

        if (!confirmedOk) {
            throw new Error(confirmedData.error);
        }

        // Get Document and send updatedDocument to redux to be merge
        // We need to refresh document.state and document.validEvents
        const { ok: documentOk, data: documentData } = yield call(
            apiClient.getDocument,
            document
        );

        // Failed to refresh document
        if (!documentOk) {
            throw new Error(documentData.error);
        }

        // - Update file in REDUX to add checkmark and add backend response
        yield put({
            type: DocumentsTypes.UPLOAD_FILE_SUCCESS,
            documentCompositeKey: document,
            updatedDocument: documentData,
            file: {
                ...file,
                ...data
            }
        });

        return { ok, data };
    } catch (error) {
        yield put({
            type: DocumentsTypes.UPLOAD_FILE_ERROR,
            document,
            uploadKey: file.uploadKey,
            error
        });

        return { ok: false, data: error };
    }
}

// Delete file for document
export function* fileDeleteRequest({
    document,
    file
}: {
    document: Document;
    file: DocumentFile;
}) {
    try {
        // Delete file in backend
        if (file.fileId) {
            const { data, ok } = yield call(
                apiClient.deleteFile,
                {
                    ...document,
                    year: document.year || 0
                },
                file.fileId as string
            );

            if (!ok) {
                throw new Error(data.error);
            }
        }

        // Get Document and send updatedDocument to redux to be merge
        // We need to refresh document.state and document.validEvents
        const { ok: documentOk, data: documentData } = yield call(
            apiClient.getDocument,
            document
        );

        // Failed to refresh document
        if (!documentOk) {
            throw new Error(documentData.error);
        }

        yield put({
            type: DocumentsTypes.FILE_DELETE_SUCCESS,
            document,
            updatedDocument: documentData,
            file
        });

        yield put(DocumentsActions.documentsCountsRequest());
    } catch (error) {
        yield put({
            type: DocumentsTypes.FILE_DELETE_ERROR,
            document,
            file,
            error
        });
    }
}
// Confirm notification for file delete
export function* fileDeleteSuccess() {
    yield put(
        successNotification({
            text: 'application.fileDeletedSuccess'
        })
    );
}

// Error notification file delete
export function* fileDeleteError(error: any) {
    try {
        log(error);
        yield put(
            errorNotification({
                text: 'toasts.genericError'
            })
        );
    } catch (err) {
        log(error);
    }
}

// Request download file, acquire URL then go to the signed URL
export function* fileDownloadRequest({
    document,
    file
}: {
    document: Document;
    file: DocumentFile;
}) {
    try {
        // ApiClient acquire download link
        const { ok, data } = yield call(
            apiClient.acquireDownloadUrl,
            file.fileId as string,
            document
        );

        if (!ok) {
            throw new Error(data.error);
        }

        window.location.href = data.url;
    } catch (error) {
        yield put({
            type: DocumentsTypes.FILE_DOWNLOAD_ERROR,
            error,
            fileId: file.fileId,
            document
        });
    }
}

export function* fileDownloadError(error: any) {
    try {
        log(error);
        yield put(
            errorNotification({
                text: 'toasts.genericError'
            })
        );
    } catch (err) {
        log(error);
    }
}

export function* updateDocumentRequest({
    documentCompositeKey,
    payload
}: {
    documentCompositeKey: DocumentCompositeKey;
    payload: Partial<Document>;
}) {
    try {
        const { data, ok } = yield call(
            apiClient.updateDocument,
            documentCompositeKey,
            payload
        );

        if (!ok) {
            throw new Error(data.error);
        }

        yield put({
            type: DocumentsTypes.UPDATE_DOCUMENT_SUCCESS,
            documentCompositeKey,
            updatedDocument: data
        });

        yield put(DocumentsActions.documentsCountsRequest());
    } catch (error) {
        yield put({
            type: DocumentsTypes.UPDATE_DOCUMENT_ERROR,
            error
        });
    }
}

// Change state of document into the state machine
export function* updateDocumentStateRequest({
    documentCompositeKey,
    event
}: {
    documentCompositeKey: DocumentCompositeKey;
    event: Pick<Document, 'state'>;
}) {
    try {
        const { data, ok } = yield call(
            apiClient.updateDocumentState,
            documentCompositeKey,
            { event }
        );

        if (!ok) {
            throw new Error(data.error);
        }

        yield put({
            type: DocumentsTypes.UPDATE_DOCUMENT_STATE_SUCCESS,
            documentCompositeKey,
            updatedDocument: data
        });

        yield put(DocumentsActions.documentsCountsRequest());
    } catch (error) {
        yield put({
            type: DocumentsTypes.UPDATE_DOCUMENT_STATE_ERROR,
            error
        });
    }
}

export function* updateDocumentStateError(error: any) {
    try {
        log(error);
        yield put(
            errorNotification({
                text: 'documents.updateDocumentStateFailure'
            })
        );
    } catch (err) {
        log(error);
    }
}

export function* documentsCountsRequest() {
    const applicationId: number = yield select(getApplicationId);

    try {
        const [allCount, criticalCount, additionalCount] = yield [
            yield call(
                apiClient.getDocumentsCounts,
                applicationId,
                DocumentStep.All
            ),
            yield call(
                apiClient.getDocumentsCounts,
                applicationId,
                DocumentStep.Critical
            ),
            yield call(
                apiClient.getDocumentsCounts,
                applicationId,
                DocumentStep.Additional
            )
        ];

        if (!allCount.ok) {
            throw new Error(allCount.data.error);
        }

        if (!criticalCount.ok) {
            throw new Error(criticalCount.data.error);
        }

        if (!additionalCount.ok) {
            throw new Error(additionalCount.data.error);
        }

        yield put(
            DocumentsActions.documentsCountsSuccess({
                [DocumentStep.All]: allCount.data,
                [DocumentStep.Critical]: criticalCount.data,
                [DocumentStep.Additional]: additionalCount.data
            })
        );
    } catch (error) {
        yield put(DocumentsActions.documentsCountsError(error));
    }
}

export function* documentsCountsError(error: any) {
    try {
        log(error);

        yield put(
            errorNotification({
                text: 'documents.documentsError'
            })
        );
    } catch (err) {
        log(error);
    }
}

export function* filePreviewRequest({
    document,
    file
}: {
    document: Document;
    file: DocumentFile;
}) {
    try {
        // ApiClient acquire download link
        const { ok, data } = yield call(
            apiClient.previewDocumentFile,
            file.fileId as string,
            document
        );

        if (!ok) {
            throw new Error(data.error);
        }

        yield put(DocumentsActions.filePreviewSuccess(data));
    } catch (error) {
        yield put(DocumentsActions.filePreviewError(error));
    }
}

export function* filePreviewError(error: any) {
    try {
        log(error);
        yield put(
            errorNotification({
                text: 'toasts.genericError'
            })
        );
    } catch (err) {
        log(error);
    }
}

export function* fileDownloadPDFRequest({
    document,
    file
}: {
    document: Document;
    file: DocumentFile;
}) {
    try {
        // ApiClient acquire download link
        const { ok, data } = yield call(
            apiClient.acquireDocumentFilePDFUrl,
            file.fileId as string,
            document
        );

        if (!ok) {
            throw new Error(data.error);
        }

        window.location.href = data.url;
    } catch (error) {
        yield put(DocumentsActions.fileDownloadPDFError(error));
    }
}

export function* fileDownloadPDFError(error: any) {
    try {
        log(error);
        yield put(
            errorNotification({
                text: 'toasts.genericError'
            })
        );
    } catch (err) {
        log(error);
    }
}

// DOCUMENTS PDF
export function* documentPreviewRequest({ document }: { document: Document }) {
    try {
        // ApiClient acquire download link
        const { ok, data } = yield call(apiClient.previewDocument, document);

        if (!ok) {
            throw new Error(data.error);
        }

        yield put(DocumentsActions.documentPreviewSuccess(data));
    } catch (error) {
        yield put(DocumentsActions.documentPreviewError(error));
    }
}

export function* documentPreviewError(error: any) {
    try {
        log(error);
        yield put(
            errorNotification({
                text: 'toasts.genericError'
            })
        );
    } catch (err) {
        log(error);
    }
}

export function* documentDownloadPDFRequest({
    document
}: {
    document: Document;
}) {
    try {
        // ApiClient acquire download link
        const { ok, data } = yield call(
            apiClient.acquireDocumentPDFUrl,
            document
        );

        if (!ok) {
            throw new Error(data.error);
        }

        window.location.href = data.url;
    } catch (error) {
        yield put(DocumentsActions.documentDownloadPDFError(error));
    }
}

export function* documentDownloadPDFError(error: any) {
    try {
        log(error);
        yield put(
            errorNotification({
                text: 'toasts.genericError'
            })
        );
    } catch (err) {
        log(error);
    }
}

export function* createMessageRequest({ document, text, resolve, reject }) {
    try {
        const { ok, data } = yield call(
            apiClient.createDocumentMessage,
            {
                ...document,
                year: document.year || 0
            },
            { text }
        );

        if (!ok) {
            throw new Error(data.error);
        }

        yield resolve(data);
        yield put(DocumentsActions.createMessageSuccess());
    } catch (error) {
        yield reject(error);
        yield put(DocumentsActions.createMessageError(error));
    }
}

export function* editMessageRequest({
    document,
    messageId,
    text,
    resolve,
    reject
}) {
    try {
        const { ok, data } = yield call(
            apiClient.editDocumentMessage,
            {
                ...document,
                year: document.year || 0
            },
            messageId,
            { text }
        );

        if (!ok) {
            throw new Error(data.error);
        }

        yield resolve(data);
        yield put(DocumentsActions.editMessageSuccess());
    } catch (error) {
        yield reject(error);
        yield put(DocumentsActions.editMessageError(error));
    }
}

export function* deleteMessageRequest({ document, messageId }) {
    try {
        const { ok, data } = yield call(
            apiClient.deleteDocumentMessage,
            {
                ...document,
                year: document.year || 0
            },
            messageId
        );

        if (!ok) {
            throw new Error(data.error);
        }

        yield put(DocumentsActions.deleteMessageSuccess());
    } catch (error) {
        yield put(DocumentsActions.deleteMessageError(error));
    }
}

export function* messageActionSuccess() {
    const step = yield select(getRouteDocumentStep);
    // Send refresh documents
    yield put(DocumentsActions.documentsRequest(step));
}

export function* documentError(error: any) {
    try {
        log(error);
        yield put(
            errorNotification({
                text: 'toasts.genericError'
            })
        );
    } catch (err) {
        log(error);
    }
}
